
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeGridPropTypes } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from "vue";

export default defineComponent({
  components: {
    SubGrid: Grid,
  },
  props: {
    columns: { type: Array as PropType<VxeGridPropTypes.Columns>, required: true },
    sid: Number,
    visible: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props, {emit}) {
    const model = CloudFun.current?.model

    const subGrid = ref<any>({});
    const itemForm = ref<any>({});
    const editList = ref<any[]>([]);
    const subGridOptions: GridOptions = {
      title: '工單項目',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      multiselect: true,
      showFooter: true,
      expand: false,
      toolbarConfig: {
        refresh: true,
      },
      pagerConfig: {
        currentPage: 1,
        pageSize: 30,
        pageSizes: [5, 10, 20, 30, 40, 50],
        layouts: [
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "Sizes",
          "Total",
        ],
      },
      sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Ordinal', order: 'asc' }]
      },      
      columns: props.columns,
      expandConfig: {
        lazy: true,
        loadMethod: async({ row }) => {
          return await new Promise((resolve, reject) => {
            row.RowData = JSON.parse(JSON.stringify(row));
            resolve()
          });
        }
      },
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: () => {
        console.log('save');
        return new Promise((resolve, reject) => resolve());
      } },
      modalConfig: { width: "60%", height: "600", showFooter: true },
      checkboxConfig: {
        reserve: true,
      },
    }

    // const optionGrid = ref<any>()
    // const optionModal = reactive({
    //   visible: false,
    //   selectedRow: {} as any,
    //   gridOptions: {
    //     promises: {
    //       query: model ? (params) => {
    //         // params.condition = new Condition()
    //         //   .and("ActivityId", Operator.Equal, optionModal.selectedRow.Id.toString())
    //         //   .and(params.condition!);
    //         if (!params.sortings || params.sortings?.length === 0)
    //           params.sortings = [{ column: "CreatedTime", order: 1 }];
    //         return model.dispatch("option/query", params);
    //       } : undefined,
    //       queryAll: model ? () => model.dispatch('option/query') : undefined,
    //       save: model ? (params) => model.dispatch('option/save', params) : undefined
    //     }
    //   } as GridOptions,
    //   show(row: any) {
    //     optionModal.visible = true;
    //     optionModal.selectedRow = row;
    //     console.log(row)
    //     if (optionGrid.value) optionGrid.value.refresh();
    //   }
    // })

    const onCheckboxChange =  async({ checked, records, reserves, row }: any) => {
      if(checked) {
        selectedRows.value.push(row);
      } else {
        selectedRows.value = selectedRows.value.filter((i:any) => i.Id != row.Id);
      }
      // console.log(selectedRows.value)
    }

    const selectedRows = ref<any[]>([]);
    const onPageChanged = async() => {
      // console.log(selectedRows.value)
      if (selectedRows.value && selectedRows.value.length) {
        const data = subGrid.value.getData();
        for (const i of selectedRows.value) {
          var exist = data.find((e:any) => e.Id == i.Id);
          if(exist) {
            subGrid.value.setCheckboxRow(exist, true);
          }
        }
      }

      if (editList.value && editList.value.length) {
        const data = subGrid.value.getData();
        for (const item of editList.value) {
          console.log(item);
          var exist = data.find((e:any) => e.Id == item.Id);
          if(exist) {
            setEntity(exist, item);
          }
        }
      }
    }

    const onCheckboxAll = async({ checked, records, reserves }: any) => {
      if (checked) {
        for (const record of records) {
          const exist = selectedRows.value.find((e) => e.Id === record.Id);
          if (!exist) selectedRows.value.push(record);
        }
      } else {
        const data = subGrid.value.getData();
        for (const record of data) {
          selectedRows.value = selectedRows.value.filter((i:any) => i.Id != record.Id);
        }
      }
      // console.log(selectedRows.value)
    }

    watch(() => props.visible, async(value) => {
      selectedRows.value = [];
      editList.value = [];
      if(subGrid.value && subGrid.value.reload) subGrid.value.reload();
    }, { immediate: true });

    watch(() => props.sid, async(value) => {
      if(value || value == null) {
        selectedRows.value = [];
        if(subGrid.value && subGrid.value.reload) {
          subGrid.value.keyword = null;
          subGrid.value.reload();
        } 
      }
      
    }, { immediate: true });

    const getData = async() => {
      // console.log(selectedRows.value);
      if(!selectedRows.value || !selectedRows.value.length)
        return CloudFun.send('error', { subject: '執行失敗', content: '請選擇項目' });
      emit('getData', selectedRows.value);
    }

    const saveData = async(row: any) => {
      if(row) {
        setEntity(row, null);
        // console.log(subGrid.value)
        editList.value.push(row);
        subGrid.value.setRowExpand(row, false)
      }
    }

    const setEntity = async(row: any, data: any) => {
      if(data == null) {
        row.Quantity = row.RowData.Quantity;
        row.Radix = row.RowData.Radix;
        row.OrderQuantity = row.RowData.OrderQuantity;
        row.RollQuantity = row.RowData.RollQuantity;
        row.Pallets = row.RowData.Pallets;
        row.UnitPrice = row.RowData.UnitPrice;
      } else {
        row.Quantity = data.Quantity;
        row.Radix = data.Radix;
        row.OrderQuantity = data.OrderQuantity;
        row.RollQuantity = data.RollQuantity;
        row.Pallets = data.Pallets;
        row.UnitPrice = data.UnitPrice;
      }
    }
    

    return {
      editList,
      subGrid,
      subGridOptions,
      onCheckboxAll,
      onCheckboxChange,
      selectedRows,
      onPageChanged,
      getData,
      saveData,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
