
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import InvoiceItemTable from "@/components/import-data/InvoiceItemsTable.vue";
import formatDate from 'xe-utils/toDateString'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { formatAmount, formatResultTotalAmount } from '@/libs/utils'
import JobticketItemTable from "@/components/invoice/JobticketItemTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    InvoiceItemTable,
    loading: VueLoading,
    JobticketItemTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("InvoiceCreate");
    const canUpdate = checkPermissions("InvoiceUpdate");
    const canDelete = checkPermissions("InvoiceDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '發票管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: true,
      expand: true,
      checkboxConfig: {
        reserve: true,
        // checkMethod: ({ row }) => {
        //   console.log(row);
        //   grid.value.setCheckboxRow(row);
        //   return true;
        // }
      },
      printConfig: { sheetName: '發票管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '發票管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [        
        {
          field: 'InvoiceNumber',
          title: 'Invoice No',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          slots: { default: 'link', }
        },        
        // {
        //   field: 'Customer.Number',
        //   title: '客戶代號',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   treeNode: false,
        // },
        {
          field: 'PIDate',
          title: 'Date',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Consignee',
          title: '收貨人',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'Shipper',
          title: '託運人',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: "operate",
          title: "操作",
          align: "center",
          width: 150,
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model ? async (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(user && !user.IsAdmin) {
            params.condition = new Condition("EmployeeId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          return model.dispatch('invoice/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('invoice/query') : undefined,
        save: model ? (params) => model.dispatch('invoice/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'CustomerId',
          title: '客戶',
          span: 24,
          slots: { default: "column-CustomerId" }
        },
        {
          field: 'JobTickeIds',
          title: '工單單號',
          span: 24,
          slots: { default: "column-JobTicke" }
        },
        {
          field: 'InvoiceNumber',
          title: 'Invoice No',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { blur: (e: any) => changeDate(e.data) } }
        },
        // {
        //   field: 'CustomerPO',
        //   title: '客戶代號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'EmployeeId',
          title: '負責業務',
          span: 12,
          slots: { default: "column-employeeId" }
        },
        {
          field: 'PIDate',
          title: 'Date',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Consignee',
          title: '收貨人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Shipper',
          title: '託運人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'VesselNameVoyage',
          title: '船名航次',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PortofLoading',
          title: '裝載港口',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'FinalDestination',
          title: '目的地',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ETD',
          title: 'ETD',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ETA',
          title: 'ETA',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Country',
          title: '原產國',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'TermDelivery',
          title: '貿易條件',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Container',
        //   title: '櫃號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'Seal',
          title: 'Seal',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'OrderNumber',
          title: 'Order Number',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContainerNumber',
          title: 'Container Number',
          span: 12,
          titleWidth: 150,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },

        // {
        //   field: 'Radix',
        //   title: '套/箱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'Quantity',
        //   title: '箱/卷數',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        // },
        // {
        //   field: 'UnitPrice',
        //   title: '單位價格',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 3 } }
        // },
        {
          field: 'Status',
          title: '狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.InvoiceStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'HTSCode',
          title: 'HTS Code',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'SQM',
          title: 'SQ.M (平方米)',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },        
        {
          field: 'ShippingMark',
          title: 'Shipping Mark',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, laceholder: '請輸入文字' } }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },
        {
          title: '選擇項目',
          span: 24,
          slots: { default: 'column-Item' }
        },
        {
          title: '選擇項目',
          span: 24,
          slots: { default: 'column-MutiItem' }
        },
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-InvoiceItem' }
        },
        {
          field: 'Pallets',
          title: '總棧板數量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'OrderQuantity',
          title: '總下單件數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'TotleAmount',
          title: '總金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          title: '其他費用',
          span: 24,
        },
        {
          field: 'LicenseFee',
          title: '版費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'InspectionFee',
          title: '檢驗費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'ToolingFee',
          title: '模具費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'SampleFee',
          title: '樣品費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'ShippingFee',
          title: '運費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
      ],
      rules: {
        CustomerId: [{ required: true }],
        InvoiceNumber: [{ required: true }],
      }
    }

    const changeDate = (data: any) => {
      console.log('test');
    }
    const itemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      var items = itemsTable?.value.getData();
      if(items)
        row.Items = items;
      
      submit();
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.condition!.and("Number", Operator.IsNotNull, null).and(new Condition("Number", Operator.Equal, "C004").or("Number", Operator.Equal, "C003").or("Number", Operator.Equal, "C020").or("Number", Operator.Equal, "C021"));
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const ItemSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      multiselect: false,
      columns: [
        {
          field: "JobTicke.JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      formatText: (row) => row.Product.ProductNo && row.Product.Name ? `${row.JobTicke && row.JobTicke.JobTickeNo ? row.JobTicke.JobTickeNo + '-' : ''}${row.Product.ProductNo}-${row.Product.ProductName}` : row.Product.ProductName ?? row.Product.ProductNo ?? row.JobTicke.JobTickeNo,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTickeItem/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("jobTickeItem/query", params) // eslint-disable-line
        }
      }
    };

    const addItem = async (row: any) => {      
      try {
        var item = await model!.dispatch("jobTickeItem/find", row.ItemId);
        if(item?.Id) {
          row.Item = item;
          row.ItemId = null;
        }
      } catch (error: any) {
        CloudFun.send('error', { subject: '執行失敗', content: error })
      }
    }

    const initData = (row: any, callback: any) => {
      row.Quantity = 0;
      row.OrderQuantity = 0;
      row.TotleCBM = 0;
      row.TotleAmount = 0;
      row.SQM = 0;

      row.LicenseFee = 0;
      row.InspectionFee = 0;
      row.ToolingFee = 0;
      row.SampleFee = 0;
      row.ShippingFee = 0;

      row.Items = [] as any;
      row.Status = 0;
      status.value = 0;
      row.JobTickeIds = [] as any;
      row.JobTickets = null;

      callback();
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async (row: any) => {
      try {
        isLoading.value = true;
        var data = await model!.dispatch('invoice/exportExcel', row.Id);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "發票資料.xlsx";
        link.click();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const toDocument = async (row: any) => {      
      if(row.Id) {
        try {
          await model?.dispatch('invoice/update', row);
          await model?.dispatch('invoice/invoiceToDocument', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生進款單`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const status = ref(0);
    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('invoice/find', row.Id) : undefined
      if(entity) {
        Object.assign(row, entity);
      }
      status.value = row.Status;
      callback();
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Number",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/InvoiceReportView?invId=${row.Id}`)
    }

    const sumItems = (data: any, prop: string) => {
      if(data.length == 0) return 0;
      return data.map((e: any) => {
          return e[prop] ?? 0;
        })
        .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b));
    };

    const updateTotal = (data: any) => {
      const items = itemsTable?.value.getData();
      if(data && items) {
        data.OrderQuantity = sumItems(items, 'OrderQuantity'); //總下單件數
        data.TotleAmount = formatResultTotalAmount(sumItems(items, 'TotleAmount')); //金額總計
        data.Pallets = sumItems(items, 'Pallets'); //總棧板數量
      }
    }

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
      async save({ type }: any) {
        if (type >= 0) {
          try {
            await model?.dispatch('documentFlow/deleteAll', { type })
            linkModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認刪除類型' })
        }
      }
    })

    const exportMutiExcel = async() => {
      const selectedRow = selectedRows.value; //grid.value.selectedRows;
      if(selectedRow && selectedRow.length > 0) {
        //const ids = selectedRow.map((e: any) => e.Id);
        //if(ids && ids.length > 0) {
          try {
            isLoading.value = true;
            var data = await model!.dispatch('invoice/exportSelectedExcel', selectedRow);
            const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "發票資料.xlsx";
            link.click();
          } catch (e: any) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: e,
            });
          } finally {
            isLoading.value = false;
          }
        }
      //}
      
    }

    const selectedGrid = ref<any>()
    const selectedModal = reactive({
      visible: false,
      selectedRow: {} as any,
      items: [],
      jobTickets: [] as any,
      selectedJobTicket: null,
      columns: [   
        {
          field: "JobTicke.JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },         
        {
          field: "Ordinal",
          title: "項次",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },          
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductSize",
          title: "規格",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
        {
          field: "Quantity",
          title: "箱數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Radix",
        //   title: "套/箱",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },      
        {
          field: "OrderQuantity",
          title: "總下單件數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "RollQuantity",
        //   title: "總卷數",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        // {
        //   field: "Pallets",
        //   title: "總棧板數量",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        {
          field: "UnitPrice",
          title: "單位價格",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "TotleAmount",
          title: "總金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          // formatter: ({  }) => formatAmount(cellValue)
        },
        // {
        //   field: "Remark",
        //   title: "備註",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
      ],
      gridOptions: {
        promises: {
          query: model ? async(params) => {
            if(selectedModal.selectedRow.JobTickeIds && selectedModal.selectedRow.JobTickeIds.length) {
              params.condition = new Condition()
                .and("JobTickeId", Operator.In, selectedModal.selectedRow.JobTickeIds)
                .and(params.condition!);

              if(selectedModal.selectedJobTicket) {
                params.condition.and("JobTicke.JobTickeNo", Operator.Equal, selectedModal.selectedJobTicket)
              }
            }
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: 'CreatedTime', order: 1 }];
            const result = await model!.dispatch("jobTickeItem/query", params);
            // console.log(result)
            return result;
          } : undefined,
          queryAll: model ? () => model.dispatch('jobTickeItem/query') : undefined,
          // save: model ? (params) => model.dispatch('question/save', params) : undefined
        }
      } as GridOptions,
      async show(row: any) {
        selectedModal.visible = true;
        // console.log(row)
        selectedModal.selectedRow = row;
        selectedModal.selectedJobTicket = null;
        selectedModal.jobTickets = selectedModal.selectedRow.JobTickeIds ? await getJobTickets(selectedModal.selectedRow) : null;
        if (selectedGrid.value) selectedGrid.value.refresh();
      },
      close() {
        // selectedModal.selectedRow = null;
        // selectedModal.items = [];
        if (selectedGrid.value) selectedGrid.value.refresh();
        selectedModal.visible = false;
        changeItems.value = false;
      },
      async reloadByNo(number: any) {
        selectedModal.selectedJobTicket = number;
      }
    })

    const selectedRows = ref<any[]>([]);
    const onPageChanged = async() => {
      // console.log(selectedRows.value)
      if (selectedRows.value && selectedRows.value.length) {
        const data = grid.value.getData();
        for (const i of selectedRows.value) {
          var exist = data.find((e:any) => e.Id == i);
          if(exist) {
            grid.value.setCheckboxRow(exist, true);
          }
        }
      }
    }

    const onCheckboxChange =  async({ checked, records, reserves, row }: any) => {
      if(checked) {
        selectedRows.value.push(row.Id);
      } else {
        selectedRows.value = selectedRows.value.filter((i:any) => i != row.Id);
      }
      // console.log(selectedRows.value)
    }

    const onCheckboxAll = async({ checked, records, reserves }: any) => {
      if (checked) {
        for (const record of records) {
          const exist = selectedRows.value.find((e) => e === record.Id);
          if (!exist) selectedRows.value.push(record.Id);
        }
      } else {
        const data = grid.value.getData();
        for (const record of data) {
          selectedRows.value = selectedRows.value.filter((i:any) => i != record.Id);
        }
      }
      // console.log(selectedRows.value)
    }

    const changeItems = ref<boolean>(false);

    const getJobticketItems = (data: any) => {
      //console.log(data)
      if(data && data.length) {
        selectedModal.items = data;
        changeItems.value = true;
      }
    }

    const exportMutiPDF = async() => {
      const selectedRow = selectedRows.value;
      if(selectedRow && selectedRow.length > 0) {
        const ids = selectedRow.join();
        //console.log(ids)
        window.open(`https://report.imp.cloudfun.app/api/ReportView/PIReportView?piId=${ids}`)
      }
    }

    /** 多選工單 */
    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      multiselect: true,
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          if(customerId.value) params.condition = new Condition("CustomerId", Operator.Equal, customerId.value).and(params.condition!);
          return model!.dispatch("jobTicke/query", params);
        }
      }
    };

    const customerId = ref<any>(0);
    const changeCustomer = (id: any) => {
      customerId.value = id;
    }

    const getJobTickets = async(data: any) => {
      const condition = new Condition("Id", Operator.In, data.JobTickeIds);
      const sortings = [{ column: 'CreatedTime', order: 0 }]
      var result = await model!.dispatch("jobTicke/query", { condition, sortings });
      return result;
    }
    
    const importAllItems = async(data: any) => {
      const condition = new Condition();
      if(data.JobTickeIds && data.JobTickeIds.length) {
        condition.and("JobTickeId", Operator.In, data.JobTickeIds);
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "請選擇工單",
        });
        return;
      }
      const sortings = [{ column: 'CreatedTime', order: 0 }]
      const result = await model!.dispatch("jobTickeItem/query",  { condition, sortings });
      // console.log(result);
      if(result && result.length > 0) {
        getJobticketItems(result);
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無工單項目",
        });
        return;
      }
    }

    const jobTickeChange = async (data: any) => {
      if(data.JobTickeIds && data.JobTickeIds.length) {        
        var result = await getJobTickets(data);
        //console.log(result)
        if(result && result.length) {
          //console.log(result[0])
          data.InvoiceNumber = result[0]?.JobTickeNo;
          data.CustomerId = data.CustomerId ?? result[0]?.Customer.Id;
          data.Consignee = result[0]?.Customer.Consignee;
          data.Shipper = result[0]?.Customer.Shipper;
          data.Country = "Taiwan";
          data.ShippingMark = result[0]?.Customer.ShippingMark;
          if(result[0]?.EmployeeId) data.EmployeeId = result[0]?.EmployeeId;
        }
      } else {
        data.InvoiceNumber = null;
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      formSubmit,
      itemsTable,
      canCreate,
      addItem,
      ItemSelectorOptions,
      exportExcel,
      isLoading,
      status,
      onGridEdit,
      toDocument,
      customerSelectorOptions,
      exportReport,
      updateTotal,
      formatAmount,
      formatDate,
      linkVXEModal,
      linkModal,
      exportMutiExcel,
      selectedGrid,
      selectedModal,
      onPageChanged,
      selectedRows,
      onCheckboxChange,
      onCheckboxAll,
      getJobticketItems,
      exportMutiPDF,
      changeItems,
      jobTickeSelectorOptions,
      jobTickeChange,
      changeCustomer,
      importAllItems,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
